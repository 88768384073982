var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    { staticClass: "sorting-form", attrs: { id: "sortingForm" } },
    [
      _vm.submitted
        ? _c("div", { staticClass: "loading-spinner sorting-form__spinner" })
        : _vm._e(),
      _vm._v(" "),
      _c("multiselect", {
        class: "multiselect--over",
        attrs: {
          title: _vm.t("property.orderBy", "Rendezés"),
          dataKey: "sortingTypes",
          selectData: _vm.sortingTypes,
          singleSelect: true,
          noDefaultOption: true,
          defaultLabel: "Kategória",
          onChange: _vm.onSingleSelectChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }