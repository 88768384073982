<template>
  <label
    class="multiselect__btn select select--toggleable"
    v-on:click="onClick"
  >
    <span ref="textBox">
      <strong>{{title}}</strong><span v-html="suffix">{{suffix}}</span>
    </span>
  </label>
</template>

<script>
  import { t } from "App/ITI18n"

  export default {
    name: "select-title",
    props: {
      "selectData": Array , 
      "title": { type: String, required: true },
      "onClick": Function,
    },
    data: function() {
      return { 
        t,
        textBoxHeight: null,
        suffix: '',
      }
    },
    methods: {
      getSelected() {
        const selections = this.selectData.reduce(
          (joined, item) => (item.selected) ? `${joined} ${item.label},` : joined,
        '').slice(0, -1)
        this.suffix = selections ? `<strong>:</strong> ${selections}` : ''
      },

      getAbbreviatedText() {
        const count = this.selectData.reduce((count, item) => (item.selected) ? count + 1 : count, 0)
        const suffix = `${count} ${t('property.selected', 'kiválasztva')}`
        this.suffix = suffix && count ? `<strong>:</strong> ${suffix}` : ''
      },
    },
    mounted() {
      this.getSelected();
      this.textBoxHeight = this.$refs.textBox.clientHeight
    },
    watch: {
      selectData: function() {
        this.getSelected();
      }
    },
    updated() {
      if (this.textBoxHeight !== null && this.$refs.textBox.clientHeight !== this.textBoxHeight) {
        return this.getAbbreviatedText();
      }
    },
  };
  
</script>

<style scoped>

</style>
