var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-field" }, [
    _c(
      "label",
      {
        staticClass: "form-field__label",
        attrs: { for: _vm.from },
        domProps: { innerHTML: _vm._s(_vm.label) },
      },
      [_vm._v(_vm._s(_vm.label))]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "range" }, [
      _c("div", { staticClass: "input-group" }, [
        _c("span", { staticClass: "input-group__text" }, [_vm._v("min.")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.data[_vm.from],
              expression: "data[from]",
            },
          ],
          staticClass: "input input--light",
          attrs: {
            type: "number",
            pattern: "[0-9]*",
            min: "0",
            id: _vm.from,
            maxlength: "19",
            title: `min ${_vm.labelText}`,
            placeholder: _vm.placeHoldersComputed.min,
          },
          domProps: { value: _vm.data[_vm.from] },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.data, _vm.from, $event.target.value)
              },
              function ($event) {
                return _vm.updateValue(_vm.from, $event.target.valueAsNumber)
              },
            ],
          },
        }),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "range__separator" }, [_vm._v("-")]),
      _vm._v(" "),
      _c("div", { staticClass: "input-group" }, [
        _c("span", { staticClass: "input-group__text" }, [_vm._v("max.")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.data[_vm.to],
              expression: "data[to]",
            },
          ],
          staticClass: "input input--light",
          attrs: {
            type: "number",
            pattern: "[0-9]*",
            min: "0",
            maxlength: "19",
            title: `max ${_vm.labelText}`,
            placeholder: _vm.placeHoldersComputed.max,
          },
          domProps: { value: _vm.data[_vm.to] },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.data, _vm.to, $event.target.value)
              },
              function ($event) {
                return _vm.updateValue(_vm.to, $event.target.valueAsNumber)
              },
            ],
          },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }