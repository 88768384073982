<template>
  <div class="form-field">
    <label :for="from" class="form-field__label" v-html="label">{{label}}</label>
    <div class="range">
      <div class="input-group">
        <span class="input-group__text">min.</span>
        <input 
          type="number"
          pattern="[0-9]*"
          min="0"
          :id="from"
          maxlength="19"
          :title="`min ${labelText}`"
          class="input input--light"
          v-model="data[from]"
          v-on:input="updateValue(from, $event.target.valueAsNumber)"
          :placeholder="placeHoldersComputed.min"
        >
      </div>
      <span class="range__separator">-</span>
      <div class="input-group">
        <span class="input-group__text">max.</span>
        <input 
          type="number"
          pattern="[0-9]*"
          min="0"
          maxlength="19"
          :title="`max ${labelText}`"
          class="input input--light"
          v-model="data[to]"
          v-on:input="updateValue(to, $event.target.valueAsNumber)"
          :placeholder="placeHoldersComputed.max"
        >  
      </div>
    </div>
  </div>
</template>

<script>
  import { t } from "App/ITI18n"

  export default {
    name: "range",
    props: {
      "from": {  },
      "to": {  },
      "data": {  },
      "label": {  },
      "placeholderTexts": { default: ()=>({ min: t('property.Eg', 'Pl.'), max: t('property.Eg', 'Pl.') }) },
      "placeholderValues": { default: ()=>({ min: 10, max: 100 }) },
      "onChange": Function, 
    },
    data() {
      return {
        t
      }
    },
    methods: {
      updateValue(...args) {
        this.$emit("onRangeValueChange", ...args);
      },
    },
    computed: {
      labelText: function() {
        return this.label.replace(/ .*/,'').toLowerCase()
      },
      placeHoldersComputed: function (params) {
        return {
          min: `${this.placeholderTexts.min} ${this.placeholderValues.min}`,
          max: `${this.placeholderTexts.max} ${this.placeholderValues.max}`,
        }
      }
    }
  };
</script>

<style scoped>

</style>
