(function(context) {
  'use strict';

  // window.addEventListener('DOMContentLoaded', onDOMContentLoaded.bind(this));
  if ( document.readyState !== 'loading' ) {
      onDOMContentLoaded.call(this);
  } else {
      window.addEventListener('DOMContentLoaded', onDOMContentLoaded.bind(this));
  }

  function onDOMContentLoaded() {
    const nodes = document.querySelectorAll('.phone-reveal')
    nodes.forEach(node => {
      const button = node.querySelector('.reveal');
      const type = document.querySelector('body').getAttribute('data-pagetype') === 'office' ? 'office' : 'advert';
      const data = {
        'advertiserUserId': context.PropertyData.userId,
        'advertId': (type !== 'office') ? context.PropertyData.adId : null, // was leadAdId
      }
      button.addEventListener('click', function(e) { revealPhoneNumber(type, data, e); });
    })
  }

  function revealPhoneNumber(type, data, event) {
     const hiddenNode = event.target.parentNode.querySelector('.phone-link');
     const chunk1Node =  event.target.parentNode.querySelector('.chunk1');
     const buttonNode = event.target;
     const chunk1 =  chunk1Node.textContent;
     const chunk2 =  event.target.parentNode.getAttribute('data-chunk');
     const full = `${chunk1}${chunk2}`
     const link = `tel:${full.replace(/\s/g, '')}`
     hiddenNode.innerHTML = full;
     chunk1Node.classList.add('hidden');
     buttonNode.classList.add('hidden');
     hiddenNode.setAttribute('href', link);
     hiddenNode.classList.remove('hidden');
     eventTracking(type, data);
  }

  function eventTracking(type, data) {

    console.log('advert event: '+type);

    switch (type) {
      case 'office':
        gtag('event', 'click', {
          event_category: 'IT_teltab_felfedes',
          event_label: 'IT_teltab_felfedes',
          value: data.advertiserUserId + '-' + data.advertId
        });

        break;
      case 'advert':
        gtag('event', 'click', {
          event_category: 'adPhoneClick',
          event_label: 'adPhoneClick',
          value: data.advertiserUserId + '-' + data.advertId
        });

        owa_cmds.push(['trackAction', 'adPhoneClick',  'adPhoneClick'])

        break;
    }

    window.google_trackConversion({
      google_conversion_id: 1064875749,
      google_conversion_language : "en",
      google_conversion_format : "3",
      google_conversion_color : "ffffff",
      google_conversion_label : "TBucCMjAlV8Q5e3i-wM",
      google_custom_params: {
      },
      google_remarketing_only: false
    });

    if (type === 'advert') {
      // ITA
      if (typeof itaClient.track !== 'undefined') {
        itaClient.track('send', ['adPhoneClick', data.advertiserUserId, data.advertId]);
      }

      var xhttp = new XMLHttpRequest();
      xhttp.open("GET", '/api/stat/ot/phone/' + data.advertId, true);
      xhttp.send();
    }
  }

})(window);


