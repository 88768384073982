(function(context) {
    'use strict';

    // TODO: rewrite properly, common trans for backend and fronend

    context.t = function(key){
        var lang = document.body.getAttribute('data-lang') || 'hu';
        var strings = {
            'Required field' : {
                en: 'Required field',
                hu: 'Kötelező mező',
                de: 'Pflichtfeld',
            },
            'Either email or phone number is required' : {
                en: 'Either email or phone number is required',
                hu: 'Egy email vagy telefonszám megadása kötelező',
                de: 'Entweder E-Mail oder Telefonnummer ist erforderlich',
            },
            'Invalid email address' : {
                en: 'Invalid email address',
                hu: 'Érvénytelen emailcím',
                de: 'Ungültige E-Mail-Adresse',
            },
            'To use this site, please accept the General Terms of Service and the Privacy Policy.' : {
                en: 'To use this site, please accept the General Terms of Service and the Privacy Policy.',
                hu: 'Az oldal használatához kérjük fogadd el az Általános Szolgáltatási Feltételeket és az Adatkezelési Szabályzatot!',
                de: 'Um diese Website zu nutzen, akzeptieren Sie bitte die Allgemeinen Nutzungsbedingungen und die Datenschutzbestimmungen.',
            },
            'Request failed' : {
                en: 'Request failed',
                hu: 'Hiba történt a lekérdezés során',
                de: 'Anfrage fehlgeschlagen',
            },
            'At least one option is required' : {
                en: 'At least one option is required',
                hu: 'Legalább egy lehetőség megjelőlése szükséges',
                de: 'Mindestens eine Option ist erforderlich',
            },
            'Please fill in the comment field' : {
                en: 'Please fill in the comment field',
                hu: 'Kérjük, töltse ki a megjegyzés mezőt',
                de: 'Bitte füllen Sie das Kommentarfeld aus',
            },
        }
        var translated = strings[key] && strings[key][lang]; 
        return translated || key;
    }


})(window);