import { ContactForm } from 'App/ContactForm';

(function(context) {
    'use strict';

    var honeyPot = document.body.getAttribute('data-hp') || '';
    var grecaptchaSiteKey = document.body.getAttribute('data-grcsk') || '';


    function onSuccessMeasurments(response) {

        if (response.data.code !== 'message_sent') { return; }

        var dataSource =  document.getElementById('agent-detail');
        var leadUId 		= dataSource.getAttribute('data-user');

        // Google
        gtag('event', 'click', {
          event_category: 'adMailSend_new',
          event_label: 'adMailSend_new',
          value: leadUId
        });

      owa_cmds.push(['trackAction', 'adMailSend_new',  'adMailSend_new'])
    }



    document.addEventListener('DOMContentLoaded', function () {
        context.contactForm = new ContactForm({
            fieldsToValidate: {
                name: document.getElementById('form-contact-agent-name'),
                email: document.getElementById('form-contact-agent-email'),
                phone: document.getElementById('form-contact-agent-phone'),
                message: document.getElementById('form-contact-agent-message'),
                policy: document.getElementById('policy_input'),
            },
            infoNode: document.getElementById('form-contact-agent-info'),
            submitBtn: document.getElementById('form-contact-agent-submit'),
            grecaptchaSiteKey: grecaptchaSiteKey,
            apiPath: './v1/api/officecontact',
            readFormData: function () {
                var polic = document.getElementById('policy_input') || document.getElementById('policy');
                var data = {
                    name: document.getElementById('form-contact-agent-name').value,
                    email: document.getElementById('form-contact-agent-email').value || '',
                    phone: document.getElementById('form-contact-agent-phone').value || '',
                    message: document.getElementById('form-contact-agent-message').value || '',
                    advertiserId: document.getElementById('form-contact-agent-advertiser-id').value,
                    policy: polic.checked,
                };
                data[honeyPot] = document.querySelector('input.' + honeyPot).value || '';
                return data;
            },
            resetForm: function(state) {
                var polic = document.getElementById('policy_input') || document.getElementById('policy');

                document.getElementById('form-contact-agent-name').value = '';
                document.getElementById('form-contact-agent-email').value = '';
                document.getElementById('form-contact-agent-phone').value = '';
                document.getElementById('form-contact-agent-message').value = '';
                polic.checked = false;
            },
            onSuccess: onSuccessMeasurments.bind(this)
        });

    }, false);
})(window)
