var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "multicheckbox" },
    _vm._l(_vm.selectData, function (option) {
      return _c(
        "label",
        {
          key: option.slug,
          staticClass: "multicheckbox__option",
          class: {
            "multicheckbox__option--active":
              _vm.singleSelect && option.selected,
          },
        },
        [
          _c("div", { staticClass: "checkbox" }, [
            _c("input", {
              staticClass: "checkbox__input",
              attrs: {
                type: _vm.singleSelect ? "radio" : "checkbox",
                id: option.slug,
                name: _vm.checkboxName,
              },
              domProps: { value: option.slug, checked: option.selected },
              on: {
                change: function ($event) {
                  _vm.onChange(_vm.dataKey, option.slug)
                  _vm.singleSelect
                },
              },
            }),
            _vm._v(" "),
            _c("span", { class: { checkbox__text: !_vm.singleSelect } }, [
              _vm._v(_vm._s(option.label)),
            ]),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }