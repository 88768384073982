<template>
  <input
    type="text"
    :id="text"
    :name="fieldName"
    :placeholder="placeholder"
    v-model="data[text]"
    v-on:input="updateValue(text, $event.target.value)"
    data-placement="bottom"
    class="form-field__input input input--light w-100"
  >
</template>

<script>
import { t } from "App/ITI18n"

export default {
  name: "freetext",
  props: {
    "data": {  },
    "text": {  },
    "fieldName": { type: String, default: false },
    "placeholder": { type: String, default: false },
    "onChange": Function,
  },
  data() {
    return {
      t
    }
  },
  methods: {
    updateValue(...args) {
      this.$emit("onValueChange", ...args);
    },
  },
};
</script>

<style scoped>

</style>
