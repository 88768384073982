import axios from 'axios';
import MicroModal from '../vendor/micromodal/micromodal.js';
import { layzr } from '../vendor/init-layzr';
import { ViewPortWatcher } from "App/ViewPortWatcher"
import { FormValidator } from 'Vendor/FormValidator';

import Vue from "vue";
import SearchForm from "Search/SearchForm.vue";
import SortingForm from "Search/SortingForm.vue";

(function(context) {
    'use strict';

    // Constructor
    context.HitsPage = function(options) {
        this.state = {
            popoverLoading: false,
            popoverContent: '',
            popover: null,
            formUrl: '/',
            sortingUrl: '',
        }
        this.setState = setState.bind(this);
        applyState.call(this, this.state);
        init.call(this);

    }

    function setState(stateObj, callback) {
        var prevState = Object.assign({}, this.state);
        this.state = Object.assign(this.state, stateObj);
        applyState.call(this, this.state, prevState, callback);
    }

    function applyState(state, prevState, callback) {
        var prevState = (typeof prevState === 'object') ? prevState : state;
        if (JSON.stringify(prevState.searchTerms) !== JSON.stringify(state.searchTerms)) {
            renderSearchTerms.call(this, state.searchTerms)
        }
        if (typeof callback === 'function') callback();
    }

    function initMobileMenu() {
        const checkbox = document.querySelector('.header .hamburger__input');
        const header = document.querySelector('header.header');
        checkbox.addEventListener( 'change', function() {
            if(checkbox.checked) {
                header.style.bottom = 0;
            } else {
                header.style.bottom = 'initial';
            }
        });
    }

    function loadModal(type) {
        const requestConfig = {
            method: 'get',
            url: type === 'history'
                ? '/getLastseen'
                : '/addFavorites',
            params: {
                type: 'home',
            },
        };
        axios(requestConfig)
        .then(function(result) {
            const container = document.querySelector('.modal-container');
            container.innerHTML = result.data.trim();
            MicroModal.init();
            MicroModal.show('modal-default');
        }.bind(this))
        .catch(function(error) {
            error.response && console.log(error.response)
        }.bind(this));
    }

    function initModals() {

        const favoritesTrigger = document.querySelector('#favoritesButton');
        const historyTrigger = document.querySelector('#historyButton');

        favoritesTrigger.addEventListener( 'click', function(e) {
            e.stopImmediatePropagation();
            e.stopPropagation();
            e.preventDefault();
            loadModal.call(this, 'favorites');
        });

        historyTrigger.addEventListener( 'click', function(e) {
            e.stopImmediatePropagation();
            e.stopPropagation();
            e.preventDefault();
            loadModal.call(this, 'history');
        });
    }

    function getSearchUrl() {
        return this.state.formUrl + this.state.sortingUrl;
    }

    function setFormUrl(formUrl) {
        this.setState({ formUrl })
    }

    function setSortingUrl(sortingUrl) {
        this.setState({ sortingUrl })
    }

    function setSearchFilterPosition(isMobile) {
        const searchFilter = document.getElementById('search-filter');
        const searchFilterHeading = document.getElementById('search-filter-heading');

        if (isMobile) {
            this.searchFilterLabel.insertAdjacentElement('afterend', searchFilter)
        } else {
            searchFilterHeading.insertAdjacentElement('afterend', searchFilter)
        }
    }

    function initSearchForm() {
        const initialFormData = context.ITSearchForm;
        const initialSortingData = context.ITSortingForm;



        const filterSlugs = {
            en_US: {
                rentOrSaleTypes: new Map([ // c3_id
                    ['forSale', 'for-sale'],
                    ['forRent', 'for-rent'],
                ]),
                propertyTypes: new Map([ // c1_id
                    ['realEstates', 'real-estate'],
                ]),
                price: 'price',
                size: 'size',
                plotsize: 'plotsize',
                room: 'room',
                priceChanged: 'arvaltozott'
            },
            hu_HU: {
                rentOrSaleTypes: new Map([ // c3_id
                    ['forSale', 'elado'],
                    ['forRent', 'kiado'],
                ]),
                propertyTypes: new Map([ // c1_id
                    ['realEstates', 'ingatlanok'],
                ]),
                price: 'ar',
                size: 'alapterulet',
                plotsize: 'telekterulet',
                room: 'szoba',
                priceChanged: 'arvaltozott'
            },
            de_DE: {
                rentOrSaleTypes: new Map([ // c3_id
                    ['forSale', 'vermieten'],
                    ['forRent', 'kiado'],
                ]),
                propertyTypes: new Map([ // c1_id
                    ['realEstates', 'immobilien'],
                ]),
                price: 'preis',
                size: 'wohnflache',
                plotsize: 'grundflache',
                room: 'zimmer',
            }
        }


        Vue.config.productionTip = false;
        new Vue({
          render: h => h(SearchForm, {
            props: {
                getSearchUrl: getSearchUrl.bind(this),
                setFormUrl: setFormUrl.bind(this),
                initialFormData,
                locale: ITI18n.locale,
                translations: ITI18n.translations,
                filterSlugs,
            },
          })
        }).$mount("#searchFormVue");

        new Vue({
          render: h => h(SortingForm, {
            props: {
                getSearchUrl: getSearchUrl.bind(this),
                setSortingUrl: setSortingUrl.bind(this),
                initialSortingData,
                locale: ITI18n.locale,
                translations: ITI18n.translations,
                filterSlugs,
            },
        })
        }).$mount("#sortingFormVue");

        this.viewPortWatcher.addCallback((breakpoint, isMobile) => {
            if (this.isMobile === isMobile) return;

            setSearchFilterPosition.call(this, isMobile);
            this.isMobile = isMobile;
        });

    }

    function initSearchFilterBtnFunction() {
        const searchFilterToggled = document.getElementById('search-filter-toggler');

        document.getElementById('search-filter-btn').addEventListener('click', () => {
            this.searchFilterLabel.scrollIntoView({
                behavior: 'smooth',
            })
            setTimeout(() => {
                searchFilterToggled.checked = true;
            }, 600);
        })
    }

    function initImageDefer() {
        // const instance = Layzr({
        //     normal: 'data-defer-src',
        //     threshold: 100,
        // })
    }

    function initAll()  {
        this.viewPortWatcher = new ViewPortWatcher();
        this.searchFilterLabel = document.getElementById('search-filter-label');
        this.isMobile = this.viewPortWatcher.isMobile;
        if (this.isMobile) {
            setSearchFilterPosition.call(this, this.isMobile);
        }
        initMobileMenu.call(this);
        // initImageDefer.call(this);
        initModals.call(this);
        initSearchForm.call(this);
        FormValidator('#ad-watcher-form');
        initSearchFilterBtnFunction.call(this);
    }

    function init() {
        if ( document.readyState !== 'loading' ) {
            initAll.call(this);
        } else {
            window.addEventListener('DOMContentLoaded', function(){ initAll.call(this);}.bind(this));
        }
    }

})(window);
var hitsPage = new HitsPage();
