<template>
  <form :action="formUrl" id="searchForm">
    <div class="search-filter__block">
        <div class="form-field">
            <label for="searchLocation" class="form-field__label">
              {{t('property.typeHereTheLocation', 'Hol keres ingatlant?')}}
            </label>
            <autocomplete
              v-bind:addTerm="addLocationTerm"
              :placeholder="`${t('property.Eg', 'Pl.')} Budapest`"
            />
        </div>
        <div class="search-filter__terms">
            <slug
              v-for="term in locations"
              :key="term.termValue"
              :termValue="term.termValue"
              :title="term.title"
              v-bind:slug="term"
              v-on:removeSlug="removeLocationTerm"
            />
        </div>
    </div>

    <multiselect
      :title="t('property.forSaleForRent','Eladó / Kiadó')"
      :dataKey="'rentOrSaleTypes'"
      :selectData="rentOrSaleTypes"
      :singleSelect="true"
      :defaultLabel="'Eladó / Kiadó'"
      :onChange="onSingleSelectChange"
    />

    <multiselect
      :title="t('property.category','Kategória')"
      :dataKey="'propertyTypes'"
      :selectData="propertyTypes"
      :singleSelect="true"
      :defaultLabel="'Kategória'"
      :onChange="onSingleSelectChange"
    />

    <multiselect
      :title="t('property.type','Típus')"
      :dataKey="'propertySubTypes'"
      :selectData="propertySubTypes"
      v-if="propertySubTypes.length"
      :onChange="onMultiSelectChange"
    />

    <multiselect
      :title="t('property.condition','Állapot')"
      :dataKey="'conditionTypes'"
      :selectData="conditionTypes"
      :onChange="onMultiSelectChange"
      v-if="!propertyTypes.find(p => p.selected && p.slug === 'ujepitesu-projekt')"
    />

    <multiselect
      :title="t('property.heating','Fűtés')"
      :dataKey="'heatingTypes'"
      :selectData="heatingTypes"
      :onChange="onMultiSelectChange"
    />

    <multiselect
      :title="t('property.floor','Emelet')"
      :dataKey="'floorTypes'"
      :selectData="floorTypes"
      v-if="isPropertyTypeFlat"
      :onChange="onMultiSelectChange"
    />

    <multiselect
      :title="'Árváltozás'"
      :dataKey="'priceChanged'"
      :selectData="priceChanged"
      :singleSelect="true"
      :noDefaultOption="true"
      :onChange="onSingleSelectChange"
    />

    <div class="search-filter__block">
      <range
        :data="this"
        :from="'priceMin'"
        :to="'priceMax'"
        v-on:onRangeValueChange="onRangeValueChange"
        :label="`${t('property.price','Ár')} (${isForSale ? t('price.millionPrefix','M') : t('price.thousandPrefix','E')} ${t('property.ft','Ft')})`"
        :placeholderValues="{ min: 10, max: 100 }"
      />

      <range
        :data="this"
        :from="'priceMinEur'"
        :to="'priceMaxEur'"
        v-on:onRangeValueChange="onRangeValueChange"
        :label="`Ár (E EUR)`"
        :placeholderValues="{ min: 1, max: 100 }"
      />

      <range
        :data="this"
        :from="'sizeMin'"
        :to="'sizeMax'"
        v-on:onRangeValueChange="onRangeValueChange"
        :label="`${t('property.size','Alapterület')} (${t('property.m2','m<sup>2</sup>')})`"
        :placeholderValues="{ min: 100, max: 120 }"
      />

      <range
        :data="this"
        :from="'plotSizeMin'"
        :to="'plotSizeMax'"
        v-on:onRangeValueChange="onRangeValueChange"
        :label="`${t('property.plotSize','Telekterület')} (${t('property.m2','m<sup>2</sup>')})`"
        :placeholderValues="{ min: 600, max: 899 }"
      />

      <range
        :data="this"
        :from="'roomsNumMin'"
        :to="'roomsNumMax'"
        v-on:onRangeValueChange="onRangeValueChange"
        :label="`${t('property.rooms','Szobák')} (${t('property.db','db')})`"
        :placeholderValues="{ min: 1, max: 4 }"
      />
    </div>

    <button
      class="search-filter__block btn btn--search" type="submit"
      v-on:click="submit($event)"
    >
      <span>{{t('property.searchNow', 'Keresés')}}</span>
      <span class="hits-counter" v-if="typeof hitsCount !== 'undefined'">
        <br>
        (<animated-number :value="hitsCount" :round="1" :formatValue="formatNumber" :duration="1000"/>
        {{` ${t('property.resultsFound','várható találat')}`}})
      </span>
    </button>
  </form>
</template>

<script>
  import axios from "axios";
  import AnimatedNumber from "animated-number-vue";
  import get from "lodash.get";
  import { Slug, Range, Multiselect, Autocomplete } from "Search/components"
  import { t } from "App/ITI18n"

  export default {
    name: "searchForm",
    props: ["initialFormData", "locale", "translations", "filterSlugs", "getSearchUrl", "setFormUrl"],
    components: { Slug, Multiselect, Autocomplete, Range, AnimatedNumber },
    mounted: function() {
      this.t = t
      const initObj = ({ ...this.initialFormData })
      delete initObj.propertyTypesWithSubTypes
      Object.entries(initObj).forEach(item => {
        this[item[0]] = item[1];
      });
    },
    data: function() {
      return {
        t,
        formUrl: '/',
        hitsCount: undefined,
        selectedPropertyType: null,
        debounceTimeout: null,
        firstPropertyTypeChange: true,

        firstHitsCountAfterInit: true,
        hitsCountInitializing: false,
        hitsCountInitTimeOut: null,

        isForSale: false,

        locations: [],
        rentOrSaleTypes: [],
        propertyTypes: [],
        propertySubTypes: [],
        conditionTypes: [],
        priceChanged: [],
        heatingTypes: [],
        floorTypes: [],
        priceMin: null,
        priceMax: null,
        priceMinEur: null,
        priceMaxEur: null,
        sizeMin: null,
        sizeMax: null,
        roomsNumMin: null,
        roomsNumMax: null,
        plotSizeMin: null,
        plotSizeMax: null,

        formValuesCollected: [],
      };
    },
    watch: {
      rentOrSaleTypes: { handler: 'onSelectInputChange', deep: true, },
      locations: { handler: 'onSelectInputChange', deep: true, },
      propertyTypes: { handler: 'onPropertyTypechange', deep: true, },
      propertySubTypes: { handler: 'onSelectInputChange', deep: true, },
      conditionTypes: { handler: 'onSelectInputChange', deep: true, },
      priceChanged: { handler: 'onSelectInputChange', deep: true, },
      heatingTypes: { handler: 'onSelectInputChange', deep: true, },
      floorTypes: { handler: 'onSelectInputChange', deep: true, },
      priceMin: { handler: 'onRangeInputChange', deep: true, },
      priceMax: { handler: 'onRangeInputChange', deep: true, },
      priceMinEur: { handler: 'onRangeInputChange', deep: true, },
      priceMaxEur: { handler: 'onRangeInputChange', deep: true, },
      sizeMin: { handler: 'onRangeInputChange', deep: true, },
      sizeMax: { handler: 'onRangeInputChange', deep: true, },
      plotSizeMin: { handler: 'onRangeInputChange', deep: true, },
      plotSizeMax: { handler: 'onRangeInputChange', deep: true, },
      roomsNumMin: { handler: 'onRangeInputChange', deep: true, },
      roomsNumMax: { handler: 'onRangeInputChange', deep: true, },
    },
    methods: {
      formatNumber(value) {
        return value.toLocaleString('hu');
      },
      getValues: function() {
        const rentOrSaleTypesSlug = this.rentOrSaleTypes.filter(item => item.selected).map(item => item.slug)
        this.isForSale = rentOrSaleTypesSlug[0] === this.filterSlugs[this.locale]['rentOrSaleTypes'].get('forSale');

        const formValues = {
          locations: this.locations.map(item => item.termValue),
          rentOrSaleTypes: rentOrSaleTypesSlug,
          propertyTypes: this.propertyTypes.filter(item => item.selected).map(item => item.slug),
          propertySubTypes: this.propertySubTypes.filter(item => item.selected).map(item => item.slug),
          conditionTypes: this.conditionTypes.filter(item => item.selected).map(item => item.slug),
          priceChanged: this.priceChanged.filter(item => item.selected).map(item => item.slug),
          heatingTypes: this.heatingTypes.filter(item => item.selected).map(item => item.slug),
          floorTypes: this.floorTypes.filter(item => item.selected).map(item => item.slug),
          priceMin: this.isForSale ? this.priceMin * 1_000_000 : this.priceMin * 1000,
          priceMax: this.isForSale ? this.priceMax * 1_000_000 : this.priceMax * 1000,
          priceMinEur: this.priceMinEur * 1000,
          priceMaxEur: this.priceMaxEur * 1000,
          sizeMin: this.sizeMin,
          sizeMax: this.sizeMax,
          plotSizeMin: this.plotSizeMin,
          plotSizeMax: this.plotSizeMax,
          roomsNumMin: this.roomsNumMin,
          roomsNumMax: this.roomsNumMax,
        }
        this.formValuesCollected = formValues
      },
      onFormChange: function() {
        this.getValues()
        this.setFormUrl(this.buildFormUrl());
      },
      onSelectInputChange: async function() {
        this.onFormChange()
        await this.checkHitsCount()
      },
      onPropertyTypechange: async function() {
        this.selectedPropertyType = this.propertyTypes.reduce(
           (selected, item) =>(item.selected)?item.slug:selected
        ,'')
        if (this.firstPropertyTypeChange) {
          this.firstPropertyTypeChange = false
        } else {
          this.propertySubTypes = this.getSubCategories(this.selectedPropertyType)
        }
        this.onFormChange()
        await this.checkHitsCount()
      },
      onRangeInputChange: function() {
        this.onFormChange()
        clearTimeout(this.debounceTimeout)

        this.debounceTimeout = setTimeout(async () => {
          await this.checkHitsCount()
        }, 900)
      },
      getSubCategories: function (selectedPropertyType) {
        if (!selectedPropertyType) return []
        const psc = this.initialFormData.propertyTypesWithSubTypes
        const category = Object.values(psc).find(item => item.slug === selectedPropertyType)
        const subCategory = get(category, 'sub')
        if (!subCategory) return []
        return Object.values(subCategory).map(item => ({ ...item, label: item.name, selected: false }));
      },
      buildFormUrl: function () {
        const formData = this.formValuesCollected
        const joinSlugs = (slugArr, defaultSlug = '') => (slugArr.length)
          ? `+${slugArr.join('+')}`
          : defaultSlug
        const locale = (this.locale !== 'hu_HU')
          ? `/${this.locale.slice(0,2)}`
          : ''
        const locations = formData.locations.join('+')
        const rentOrSaleTypesToTrim = joinSlugs(formData.rentOrSaleTypes)
        const rentOrSaleTypes = (locations.length)
          ? rentOrSaleTypesToTrim
          : rentOrSaleTypesToTrim.substring(1)
        const defaultPropertyType =   this.filterSlugs[this.locale]['propertyTypes'].get('realEstates')
        const propertyTypesToTrim = joinSlugs(formData.propertyTypes, `+${defaultPropertyType}`)
        const propertySubTypes = joinSlugs(formData.propertySubTypes)
        const floorTypes = this.isPropertyTypeFlat
          ? joinSlugs(formData.floorTypes)
          : '';
        const propertyTypes = (propertySubTypes)
          ? ''
          : (locations.length || rentOrSaleTypes.length)
            ? propertyTypesToTrim
            : propertyTypesToTrim.substring(1)
        const conditionTypes = joinSlugs(formData.conditionTypes)
        const heatingTypes = joinSlugs(formData.heatingTypes)
        const createRangeSlug = (filterSlug, slugArr) => {
          if (slugArr.filter(s => s).length === 0) {
            return ''
          }
          const rangeSlugs = slugArr
            .map(s => s ? `-${s}`: '-')
            .join('')
          return `+${filterSlug}${rangeSlugs}`
        }

        const createSingleSlug = (filterSlug, slug) => {
          if ((slug || []).length) {
            return `+${filterSlug}-${slug}`
          }

          return ''
        }

        let price;
        let for_eur;
        if(formData.priceMinEur || formData.priceMaxEur) {
          let eurRate = document.querySelector('meta[property="eur_rate"]').content;
          console.log('eur_rate: '+eurRate);

          price = createRangeSlug(this.filterSlugs[this.locale]['price'], [
             (formData.priceMinEur*eurRate),
             (formData.priceMaxEur*eurRate),
          ]);

           for_eur = '?for_eur=1';
        } else {
          price = createRangeSlug(this.filterSlugs[this.locale]['price'], [
            formData.priceMin,
            formData.priceMax,
          ]);

          for_eur = '';
        }
        const size = createRangeSlug(this.filterSlugs[this.locale]['size'], [
          formData.sizeMin,
          formData.sizeMax,
        ])
        const plotSize = createRangeSlug(this.filterSlugs[this.locale]['plotsize'], [
          formData.plotSizeMin,
          formData.plotSizeMax,
        ])
        const roomsNum = createRangeSlug(this.filterSlugs[this.locale]['room'], [
          formData.roomsNumMin,
          formData.roomsNumMax,
        ])

        const priceChanged = createSingleSlug(this.filterSlugs[this.locale]['priceChanged'], formData.priceChanged)

        console.log(price);
        const surl = `${locale}/${locations}${rentOrSaleTypes}${propertyTypes}${propertySubTypes}${conditionTypes}${heatingTypes}${floorTypes}${priceChanged}${price}${size}${plotSize}${roomsNum}${for_eur}`

        console.log(surl);

        return surl;
      },
      checkHitsCount: async function () {
        if (this.hitsCountInitializing) return;

        if (this.hitsCountInitTimeOut) {
          clearTimeout(this.hitsCountInitTimeOut)
          this.hitsCountInitTimeOut = null
          return;
        }

        if (this.firstHitsCountAfterInit) {
          this.firstHitsCountAfterInit = false;
          this.hitsCountInitializing = true;
          this.hitsCountInitTimeOut = setTimeout(() => {
            this.hitsCountInitializing = false
          }, 500);
        }

        const result = await axios.get('v1/api/hitscount', {params: {json: this.formValuesCollected}});
        this.hitsCount = get(result, 'data.data.hitsCount');
      },
      onMultiSelectChange: function(dataKey, slug) {
        this[dataKey] = this[dataKey].map( item => (item.slug === slug)
          ? { ...item, selected: !item.selected }
          : item
        );
      },
      onSingleSelectChange: function(dataKey, slug) {
        if (slug === null) {
          this[dataKey] = this[dataKey].map( item => ({ ...item, selected: false }))
        } else {
          this[dataKey] = this[dataKey].map( item => (item.slug === slug)
            ? { ...item, selected: true }
            : { ...item, selected: false }
          );
        }

      },
      onRangeValueChange: function(dataKey, value) {
        this[dataKey] = value
      },
      addLocationTerm: function (term) {
        if (!this.locations.find( searchTerm => searchTerm.termValue === term.termValue)) {
            this.locations = [ ...this.locations, term ];
        }
      },
      removeLocationTerm: function (termValue) {
          this.locations = this.locations.filter(searchTerm => searchTerm.termValue !== termValue );
      },
      submit: function(event) {
        if (event) {
          event.preventDefault()
        }
        window.location.href = this.getSearchUrl()
      },
    },
    computed: {
      isPropertyTypeFlat: function(event) {
        const sp = this.selectedPropertyType
        return sp === 'lakas'|| sp === 'flat' || sp === 'wohnungs'
      }
    }
  };
</script>

<style>
</style>
