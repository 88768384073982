const FormValidator = (formSelector) => {
  const form = document.querySelector(formSelector);
  if (!form) return;
  
  const submitBtn = form.querySelector('button[type="submit"]');
  const fields = [...form.querySelectorAll('input'), ...form.querySelectorAll('textarea')];


  const hasError = (field) => {
    const { validity } = field;

    if (validity.valid) return;

    if (validity.valueMissing) {
      return 'A mező kitöltése kötelező';
    }

    if (field.type === 'email') {
      if (validity.typeMismatch || validity.patternMismatch) {
        return 'Kérem valós e-mail címet adjon meg';
      }
    } else if (field.name.includes('phone') && field.type === 'text') {
      if (validity.patternMismatch) {
        return 'Nem megfelelő telefonszámformátum';
      }
    }
  }

  const showError = (error, field) => {
    field.classList.add('input--error');
    submitBtn.disabled = true;

    let errorMessage = form.querySelector(`#error-for-${field.id}`);
    if (!errorMessage) {
      errorMessage = document.createElement('span');
      errorMessage.id = `error-for-${field.id}`;
      errorMessage.classList.add('form-field__error');
      if (field.type !== 'checkbox' && field.type !== 'radio') {
        field.insertAdjacentElement('afterend', errorMessage);
      } else {
        let label = field.nextElementSibling.matches('label')
          ? field.nextElementSibling
          : field.parentNode.matches('label')
            ? field.parentNode
            : null;

        if (!label) {
          return;
        }

        label.insertAdjacentElement('afterend', errorMessage);
      }
    }

    errorMessage.innerHTML = error;
  }

  const removeError = (field) => {
    field.classList.remove('input--error');
    submitBtn.disabled = false;

    const errorMessage = form.querySelector(`#error-for-${field.id}`);
    if (!errorMessage) return;

    errorMessage.parentNode.removeChild(errorMessage);
  }

  const validateForm = (field) => {
    const error = hasError(field);

    if (error) {
      showError(error, field);
      return false;
    }

    removeError(field);
    return true;
  };

  const init = () => {
    form.setAttribute('novalidate', true);

    form.addEventListener('focusout', ({target}) => validateForm(target));

    form.addEventListener('submit', e => {
      e.preventDefault();
      const validFields = fields.filter(field => validateForm(field));

      if (validFields.length === fields.length) {
        form.submit();
      }
    });
  };

  init();
};

export default FormValidator;