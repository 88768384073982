<template>
  <div class="multicheckbox">
    <label
      class="multicheckbox__option"
      v-for="option in selectData"
      :key="option.slug"
      :class="{ 'multicheckbox__option--active': singleSelect && option.selected }"
    >
      <div class="checkbox">
        <input
          :type="singleSelect ? 'radio' : 'checkbox'"
          :id="option.slug" :name="checkboxName" :value="option.slug"
          :checked="option.selected"
          v-on:change="onChange(dataKey, option.slug); singleSelect;"
          class="checkbox__input"
        >
        <span :class="{ checkbox__text: !singleSelect }">{{option.label}}</span>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: "multicheckbox",
  props: {
    "singleSelect": { type: Boolean, default: false },
    "defaultLabel": { type: String, default: '' },
    "dataKey": { type: String, required: true},
    "checkboxName": { type: String, required: true},
    "selectData": Array ,
    "noDefaultOption": { type: Boolean, default: false }
  },
  computed: {
    defaultSelected: function () {
      // console.log('dataKey', this.dataKey)
      // console.log('selectData', this.selectData)
      return !this.selectData.reduce((selected, item) => (selected || item.selected),
        false)
    }
  }
};
</script>

<style scoped>

</style>
