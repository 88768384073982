var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "input-tag",
      on: {
        click: function ($event) {
          return _vm.onClick()
        },
      },
    },
    [_vm._v(_vm._s(_vm.slug.title))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }