import axios from 'axios';

// Constructor
const ContactForm = function(options) {
    this.props = {
        readFormData: options.readFormData,
        fieldsToValidate: options.fieldsToValidate || {},
        defaultValues: options.defaultValues || {},
        resetForm: options.resetForm,
        grecaptchaSiteKey: options.grecaptchaSiteKey || null,
        apiPath: options.apiPath || '',
        infoNode: options.infoNode || document.getElementById('form-contact-agent-info'),
        submitBtn: options.submitBtn || document.getElementById('form-contact-agent-submit'),
        onAppyState: options.onAppyState || null,
        onReject: options.onReject || null,
        onSuccess: options.onSuccess || null,
    }
    this.state = {
        loading: false,
        invalid: true,
        notRecived: false,
        token: ''
    }
    this.setState = setState.bind(this);
    applyState.call(this, this.state);
    if ( document.readyState !== 'loading' ) {
        onDOMContentLoaded.call(this);
    } else {
        window.addEventListener('DOMContentLoaded', function(){ onDOMContentLoaded.call(this);}.bind(this));
    }
}

ContactForm.prototype.getGrecaptchaToken = function() {
    try {
        return grecaptcha.execute(this.props.grecaptchaSiteKey, { action: 'contactFormSent' });
    } catch(error) {
        this.setState({ loading: false });
        this.showMessage(response.data.message || t('TODO: failed to get reCaptca token'), 'danger');
    }
}

function setState(stateObj, callback) {
    var prevState = Object.assign({}, this.state);;
    this.state = Object.assign(this.state, stateObj);
    applyState.call(this, this.state, prevState, callback);
}


function applyState(state, prevState, callback) {
    var prevState = (typeof prevState === 'object') ? prevState : state;
    if (state.loading) {
        this.props.submitBtn.classList.add('spinner');
    } else {
        this.props.submitBtn.classList.remove('spinner');
    }
    if (state.loading || state.invalid) {
        this.props.submitBtn.classList.add('disabled');
        this.props.submitBtn.disabled = true;
    } else {
        this.props.submitBtn.classList.remove('disabled');
        this.props.submitBtn.disabled = false;
    }
    if (typeof callback === 'function') callback();
}

function displayError(node, error) {
    if(node) {
        [].slice.call(node.parentNode.querySelectorAll('span')).forEach(function (el) {
            node.parentNode.removeChild(el);
        }.bind(this));
    }
    if (error) {
        var errorText = document.createTextNode(error);
        var errorNode = document.createElement("span")
        errorNode.appendChild(errorText);
        node.parentNode.appendChild(errorNode);
    }
}

ContactForm.prototype.collectErrors = function(fields) {
    var errors = {};
    if (fields.name.value.length < 1) {
        errors.name = t('Required field');
    }
    if (fields.email.value.length < 1 && fields.phone.value.length < 1)  {
        errors.email = t('Either email or phone number is required');
        errors.phone = t('Either email or phone number is required');
    }
    if (fields.email.value && fields.email.value.length > 0)  {
        var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!regex.test(String(fields.email.value).toLowerCase())) {
            errors.email = t('Invalid email address');
        }
    }
    if (fields.message && fields.message.value.length < 1 )  {
        errors.message = t('Required field');
    }
    var polic = fields.policy || document.getElementById('policy');
    if (!polic.checked) {
        errors.policy = t('To use this site, please accept the General Terms of Service and the Privacy Policy.');
    }
    return errors;
}

ContactForm.prototype.displayErrors = function(errors, fields, selectedFields) {
    Object.entries(selectedFields || fields).forEach(function(field) {
        displayError.call(this, field[1], errors[field[0]] || '');
    }.bind(this))
    this.setState({ invalid: !validate.call(this,errors) });
}

function validate(errors) {
    return !Object.keys(errors).length;
}

ContactForm.prototype.checkErrors = function(fields, selectedFields) {
    var errors = this.collectErrors(fields);
    this.displayErrors(errors, fields, selectedFields);
}

function onSubmit(event) {
    event.stopImmediatePropagation();
    var errors = this.collectErrors(this.props.fieldsToValidate);
    this.displayErrors(errors, this.props.fieldsToValidate);
    if (validate.call(this, errors)) {
        this.setState({ loading: true });
        this.getGrecaptchaToken()
            .then(function(res) {
                this.setState(
                    { token: res },
                    function() {
                        var formData = getFormData.call(this);
                        sendContact.call(
                            this,
                            formData,
                            onSendSuccess.bind(this),
                            onSendReject.bind(this)
                        );
                    }.bind(this)
                )
            }.bind(this));

    }
}

ContactForm.prototype.buildFormData = function(data) {
    var formData = new FormData();
    for (var key in data) {
        var val = data[key];
        if (Array.isArray(val)) {
            formData.append(key, JSON.stringify(val));
        } else {
            formData.append(key, val);
        }
    }
    return formData
}

function getFormData() {
    var fieldsData = this.props.readFormData();
    var data = Object.assign(fieldsData, { token: this.state.token });
    var formData = this.buildFormData(data);
    return formData;
}

function sendContact(formdata, resolve, reject) {
    this.setState({ loading: true });

    axios.post(this.props.apiPath, formdata)
        .then(
            function(result) {
                if (typeof resolve === "function") resolve(result);
                this.setState({
                    loading: false,
                    notRecived: false,
                    invalid: true,
                });
            }.bind(this),
            function(error) {
                if (typeof reject === "function") reject(error.response);
                this.setState({
                    loading: false,
                    notRecived: true,
                });
            }.bind(this)
        )
        .catch(function(error) {
            console.log(error);
        });
}

function onSendSuccess(response) {
    console.log('onSendSuccess');
    console.log('response', response);
    if (!response.data || !response.data.code || response.data.code !== 'message_sent') {

        onSendReject.call(this, response);
        return;
    }
    this.showMessage(response.data.message, 'success');
    this.resetForm();
    if (typeof this.props.onSuccess === 'function') {
        try {
            this.props.onSuccess(response);
        } catch (error) {
            console.log(error);
        }
    }
}

function onSendReject(response) {
    this.showMessage((response && response.data && response.data.message) && response.data.message || t('Request failed'), 'danger');
    if (typeof this.props.onReject === 'function') {
        try {
            this.props.onReject(response);
        } catch (error) {
            console.log(error);
        }
    }
}

ContactForm.prototype.resetForm = function() {
    this.props.resetForm(this.props);
    this.setState({ invalid: true });
}

function resetMessagePane() {
    var box = this.props.infoNode;
    var textNode = box.querySelector('.alert__text');
    if (textNode) textNode.textContent = '';
    box.classList.remove('alert-success');
    box.classList.remove('alert-info');
    box.classList.remove('alert-warning');
    box.classList.remove('alert-danger');
}

ContactForm.prototype.showMessage = function(message, msgType) {
    if (typeof msgType === 'undefined') { msgType = 'info'; }
    resetMessagePane.call(this);
    var box = this.props.infoNode;
    var textNode = box.querySelector('.alert__text');
    box.classList.add('alert-'+msgType);
    textNode.textContent = message;
    if (msgType === 'success' && box.parentNode.classList.contains('info-modal')) {
        box.parentNode.classList.add('active');
    }
    box.classList.remove('hidden');
}

ContactForm.prototype.hideMessage = function() {
    resetMessagePane.call(this);
    var box = this.props.infoNode;
    box.classList.add('hidden');
    if (box.parentNode.classList.contains('info-modal')) {
        box.parentNode.classList.remove('active');
    }
}

function onMessageCloseClick() {
    this.hideMessage();
    if (!this.state.notRecived) {
        this.resetForm();
    }
}

function addListeners() {
    this.props.infoNode.addEventListener('click', onMessageCloseClick.bind(this));
    this.props.submitBtn.addEventListener('click', onSubmit.bind(this));
    Object.entries(this.props.fieldsToValidate).forEach(function(field) {
        if(field[0] === 'policy') field[1] = document.getElementById('policy');
        field[1].addEventListener('click', function() {
            var selectedFields = {};
            selectedFields[field[0]] = field[1];
            this.checkErrors(this.props.fieldsToValidate, selectedFields );
        }.bind(this));
        field[1].addEventListener('keyup', function() {
            var selectedFields = {};
            selectedFields[field[0]] = field[1];
            this.checkErrors(this.props.fieldsToValidate, selectedFields );
            if (field[0] == 'email') this.checkErrors(this.props.fieldsToValidate, {phone: this.props.fieldsToValidate['phone']});
            if (field[0] == 'phone') this.checkErrors(this.props.fieldsToValidate, {email: this.props.fieldsToValidate['email']});
        }.bind(this));
        field[1].addEventListener('blur', function() {
            var selectedFields = {};
            selectedFields[field[0]] = field[1];
            this.checkErrors(this.props.fieldsToValidate, selectedFields );
        }.bind(this));
    }.bind(this));
}

function onDOMContentLoaded() {
    addListeners.call(this);
}

export default ContactForm