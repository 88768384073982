var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeToggled,
          expression: "closeToggled",
        },
      ],
      staticClass: "multiselect",
    },
    [
      _c("select-title", {
        class: { "select--toggled": _vm.toggled },
        attrs: {
          selectData: _vm.selectData,
          title: _vm.title,
          for: _vm.title + "-toggler",
          onClick: () => (_vm.toggled = !_vm.toggled),
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "multiselect__options",
          class: { "multiselect__options--toggled": _vm.toggled },
        },
        [
          _vm.singleSelect && !_vm.noDefaultOption
            ? _c(
                "label",
                {
                  staticClass: "multiselect__option",
                  class: { "multiselect__option--active": _vm.defaultSelected },
                },
                [
                  _c("div", { staticClass: "checkbox" }, [
                    _c("input", {
                      staticClass: "checkbox__input",
                      attrs: { type: "radio", id: null, name: null },
                      domProps: { value: null, checked: _vm.defaultSelected },
                      on: {
                        change: function ($event) {
                          _vm.onChange(_vm.dataKey, null)
                          _vm.closeToggled()
                        },
                      },
                    }),
                    _vm._v(
                      "\n        " + _vm._s(_vm.defaultLabel) + "\n      "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.selectData, function (option) {
            return _c(
              "label",
              {
                key: option.slug,
                staticClass: "multiselect__option",
                class: {
                  "multiselect__option--active":
                    _vm.singleSelect && option.selected,
                },
              },
              [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    staticClass: "checkbox__input",
                    attrs: {
                      type: _vm.singleSelect ? "radio" : "checkbox",
                      id: option.slug,
                      name: option.slug,
                    },
                    domProps: { value: option.slug, checked: option.selected },
                    on: {
                      change: function ($event) {
                        _vm.onChange(_vm.dataKey, option.slug)
                        _vm.singleSelect && _vm.closeToggled()
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { class: { checkbox__text: !_vm.singleSelect } }, [
                    _vm._v(_vm._s(option.label)),
                  ]),
                ]),
              ]
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }