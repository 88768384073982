<template>
  <span class="animated-value">{{printValue}}</span>
</template>

<script>
  export default {
    name: "counting-animation",
    props: { 
      value: { type: Number },
      duration: { default: 30000 }, //ms
      defaultValue: { type: Number, default: 0 }, //ms
    },
    data: function() {
      return {
        printValue: this.value
      }
    },
    methods: {
      animateValue: function (newValue, oldValue) {
        this.printValue = newValue
      },
    },
    watch: {
      value: { handler: 'animateValue', },
    },
  };
</script>

<style scoped>

</style>
