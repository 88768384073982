<template>
  <input
    type="text"
    id="searchLocation"
    name="searchLocation"
    :placeholder="placeholder"
    :title="t('property.beginTypingTheLocationOrSelect', 'Kezdje el gépelni az elhelyezkedést (település vagy városrész), vagy válasszon az alábbi lehetőség közül.')"
    data-placement="bottom"
    class="form-field__input input input--light w-100"
  >
</template>

<script>
  import axios from "axios";
  import { AutoComplete } from "Vendor/AutoComplete"
  import { t } from "App/ITI18n"

  export default {
    name: "autocomplete",
    props: {"addTerm":{}, "placeholder": {default: ''}},
    mounted: function() {
      this.initAutoComplete.call(this);
    },
    data: function() {
      return { 
        t,
      }
    },
    methods: {
      initAutoComplete: function() {
        var ac = new AutoComplete({
          selector: '#searchLocation',
          minChars: 2,
          source: function(term, callback){
            axios.get('/api/autocomplete/location?query=' + term)
            .then(function(result) {
                callback(result.data.slice(0, 25));
            }.bind(this))
            .catch(function(error) {
                callback(error.response);
            }.bind(this));
          }.bind(this),
          renderItem: function (item, search){
            var se = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
            var re = new RegExp("(" + se.split(' ').join('|') + ")", "gi");
            var styledItem = item.locationName.replace(re, "<strong>$1</strong>");
            return '<div class="autocomplete-suggestion" data-link="'+ item.locationNamelink +'" data-val="'+ item.locationName +'">'+ styledItem +'</div>';
          },
          onSelect: function(event, term, item) {
            event.preventDefault();
            event.stopImmediatePropagation();
            this.addTerm.call(this, { 
                title: item.getAttribute('data-val'), 
                termValue: item.getAttribute('data-link') 
            });
            var input = document.querySelector('#searchLocation');
            input.value = "";
          }.bind(this),
        });
      },
    },
  };
</script>

<style scoped>

</style>
