<template>
  <span class="input-tag" v-on:click="onClick()">{{slug.title}}</span>
</template>

<script>
  export default {
    name: "slug",
    props: ["slug"],
    methods: {
      onClick() {
        this.$emit("removeSlug", this.slug.termValue);
      }
    }
  };
</script>

<style scoped>

</style>
