var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      staticClass: "multiselect__btn select select--toggleable",
      on: { click: _vm.onClick },
    },
    [
      _c("span", { ref: "textBox" }, [
        _c("strong", [_vm._v(_vm._s(_vm.title))]),
        _c("span", { domProps: { innerHTML: _vm._s(_vm.suffix) } }, [
          _vm._v(_vm._s(_vm.suffix)),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }