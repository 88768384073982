var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.data[_vm.text],
        expression: "data[text]",
      },
    ],
    staticClass: "form-field__input input input--light w-100",
    attrs: {
      type: "text",
      id: _vm.text,
      name: _vm.fieldName,
      placeholder: _vm.placeholder,
      "data-placement": "bottom",
    },
    domProps: { value: _vm.data[_vm.text] },
    on: {
      input: [
        function ($event) {
          if ($event.target.composing) return
          _vm.$set(_vm.data, _vm.text, $event.target.value)
        },
        function ($event) {
          return _vm.updateValue(_vm.text, $event.target.value)
        },
      ],
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }