var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeToggled,
          expression: "closeToggled",
        },
      ],
      staticClass: "singleselect",
    },
    [
      _c("select-title", {
        class: { "select--toggled": _vm.toggled },
        attrs: {
          selectData: _vm.selectData,
          title: _vm.title,
          for: _vm.title + "-toggler",
          onClick: () => (_vm.toggled = !_vm.toggled),
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "multiselect__options",
          class: { "multiselect__options--toggled": _vm.toggled },
        },
        [
          _c(
            "select",
            { staticClass: "select", attrs: { name: _vm.selectName } },
            _vm._l(_vm.selectData, function (option) {
              return _c("option", { domProps: { selected: option.selected } }, [
                _vm._v("\n        " + _vm._s(option.label) + "\n      "),
              ])
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }