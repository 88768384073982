<template>
  <form id="sortingForm" class="sorting-form">

    <div class="loading-spinner sorting-form__spinner" v-if="submitted"></div>

    <multiselect
      :title="t('property.orderBy','Rendezés')"
      :dataKey="'sortingTypes'"
      :selectData="sortingTypes" 
      :singleSelect="true"
      :noDefaultOption="true"
      :defaultLabel="'Kategória'"
      :onChange="onSingleSelectChange"
      :class="'multiselect--over'"
    />

  </form>
</template>

<script>
  import axios from "axios";
  import get from "lodash.get";
  import { Multiselect } from "Search/components"
  import { t } from "App/ITI18n"

  export default {
    name: "sortingForm",
    props: ["initialSortingData", "locale", "translations", "filterSlugs", "getSearchUrl", "setSortingUrl"],
    components: { Multiselect },
    mounted: function() {
      this.t = t
      const initObj = ({ ...this.initialSortingData })
      Object.entries(initObj).forEach(item => {
        this[item[0]] = item[1];
      });
    },
    data: function() {
      return { 
        t,
        sortingTypes: [],
        firstSortingTypesChange: true,
        submitted: false,
      };
    },
    watch: {
      sortingTypes: { handler: 'onFormChange', deep: true, },
    },
    methods: {
      getValues: function() {
        const formValues = {
          sortingTypes: this.sortingTypes.filter(item => item.selected).map(item => item.slug),
        }
        return formValues
      },
      onFormChange: async function() {
        const formValues = this.getValues()
        // this.formUrl = this.buildFormUrl(formValues)
        this.setSortingUrl(this.buildFormUrl(formValues));
        if (this.firstSortingTypesChange) {
          this.firstSortingTypesChange = false
        } else {
          this.submit();
        }
      },
      buildFormUrl: function (formData) {
        const joinSlugs = (slugArr, defaultSlug = '') => {
          const slugArray = slugArr.filter(s => s);
          
          return slugArray.length
            ? `+${slugArray.join('+')}`
            : defaultSlug
        }
        
        const locale = (this.locale !== 'hu_HU')
          ? `/${this.locale.slice(0,2)}`
          : ''
          const sortingTypes = joinSlugs(formData.sortingTypes)

        return `${sortingTypes}`
      },
      onSingleSelectChange: function(dataKey, slug) {
        if (slug === null) {
          this[dataKey] = this[dataKey].map( item => ({ ...item, selected: false }))
        } else {
          this[dataKey] = this[dataKey].map( item => (item.slug === slug) 
            ? { ...item, selected: true } 
            : { ...item, selected: false } 
          );
        }

      },
      submit: function(event) {
        if (event) {
          event.preventDefault()
        }
        this.submitted = true
        window.location.href = this.getSearchUrl()
      }
    },
  };
</script>

<style>
</style>
