var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    staticClass: "form-field__input input input--light w-100",
    attrs: {
      type: "text",
      id: "searchLocation",
      name: "searchLocation",
      placeholder: _vm.placeholder,
      title: _vm.t(
        "property.beginTypingTheLocationOrSelect",
        "Kezdje el gépelni az elhelyezkedést (település vagy városrész), vagy válasszon az alábbi lehetőség közül."
      ),
      "data-placement": "bottom",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }