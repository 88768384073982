<template>
  <div class="singleselect" v-click-outside="closeToggled">
    <select-title
      :selectData="selectData"
      :title="title"
      :for="title + '-toggler'"
      :onClick="() => toggled = !toggled"
      :class="{ 'select--toggled': toggled }"
    />

    <div
      class="multiselect__options"
      :class="{ 'multiselect__options--toggled': toggled }"
    >
      <select :name="selectName" class="select">
        <option v-for="option in selectData" :selected="option.selected">
          {{ option.label }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { SelectTitle } from "Search/components"

export default {
  name: "singleselect",
  components: { SelectTitle },
  props: {
    "title": { type: String, required: true },
    "singleSelect": { type: Boolean, default: false },
    "defaultLabel": { type: String, default: '' },
    "dataKey": { type: String, required: true},
    "selectName": { type: String, required: true},
    "selectData": Array ,
    "onChange": Function,
    "noDefaultOption": { type: Boolean, default: false }
  },
  computed: {
    defaultSelected: function () {
      // console.log('dataKey', this.dataKey)
      // console.log('selectData', this.selectData)
      return !this.selectData.reduce((selected, item) => (selected || item.selected),
        false)
    }
  },
  data: () => ({
    toggled: false
  }),
  directives: {
    'click-outside': {
      bind: function (el, binding, vnode) {
        vnode.context.stopProp = function(e) {
          e.stopPropagation();
        }
        vnode.context.clickOutsideCallback = function(e) {
          if (el.contains(e.target)) return;

          const { value: fn } = binding;
          if (typeof fn === 'function') {
            fn();
          }
        }

        document.querySelector('body').addEventListener('click', vnode.context.clickOutsideCallback)
      },
      unbind: function(el, binding, vnode) {
        el.removeEventListener('click', vnode.context.stopProp)
        document.querySelector('body').removeEventListener('click', vnode.context.clickOutsideCallback)
      }
    }
  },
  methods: {
    closeToggled: function() {
      this.toggled = false
    }
  }
};
</script>

<style scoped>

</style>
