<template>
  <div class="multiselect" v-click-outside="closeToggled">
    <select-title
      :selectData="selectData"
      :title="title"
      :for="title + '-toggler'"
      :onClick="() => toggled = !toggled"
      :class="{ 'select--toggled': toggled }"
    />
    <div
      class="multiselect__options"
      :class="{ 'multiselect__options--toggled': toggled }"
    >

      <label v-if="singleSelect && !noDefaultOption"
        class="multiselect__option"
        :class="{ 'multiselect__option--active': defaultSelected }"
      >
        <div class="checkbox">
          <input
            type="radio"
            :id="null" :name="null" :value="null"
            :checked="defaultSelected"
            v-on:change="onChange(dataKey, null); closeToggled();"
            class="checkbox__input"
          >
          {{defaultLabel}}
        </div>
      </label>

      <label
        class="multiselect__option"
        v-for="option in selectData"
        :key="option.slug"
        :class="{ 'multiselect__option--active': singleSelect && option.selected }"
      >
        <div class="checkbox">
          <input
            :type="singleSelect ? 'radio' : 'checkbox'"
            :id="option.slug" :name="option.slug" :value="option.slug"
            :checked="option.selected"
            v-on:change="onChange(dataKey, option.slug); singleSelect && closeToggled();"
            class="checkbox__input"
          >
          <span :class="{ checkbox__text: !singleSelect }">{{option.label}}</span>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
  import { SelectTitle } from "Search/components"

  export default {
    name: "multiselect",
    components: { SelectTitle },
    props: {
      "title": { type: String, required: true },
      "singleSelect": { type: Boolean, default: false },
      "defaultLabel": { type: String, default: '' },
      "dataKey": { type: String, required: true},
      "selectData": Array ,
      "onChange": Function,
      "noDefaultOption": { type: Boolean, default: false }
    },
    computed: {
      defaultSelected: function () {
        // console.log('dataKey', this.dataKey)
        // console.log('selectData', this.selectData)
        return !this.selectData.reduce((selected, item) => (selected || item.selected),
        false)
      }
    },
    data: () => ({
      toggled: false
    }),
    directives: {
      'click-outside': {
        bind: function (el, binding, vnode) {
          vnode.context.stopProp = function(e) {
            e.stopPropagation();
          }
          vnode.context.clickOutsideCallback = function(e) {
            if (el.contains(e.target)) return;

            const { value: fn } = binding;
            if (typeof fn === 'function') {
              fn();
            }
          }

          document.querySelector('body').addEventListener('click', vnode.context.clickOutsideCallback)
        },
        unbind: function(el, binding, vnode) {
          el.removeEventListener('click', vnode.context.stopProp)
          document.querySelector('body').removeEventListener('click', vnode.context.clickOutsideCallback)
        }
      }
    },
    methods: {
      closeToggled: function() {
        this.toggled = false
      }
    }
  };
</script>

<style scoped>

</style>
